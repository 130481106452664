import TYPES from './types';
import { createManifestation } from '../api';
import { encryptAes, dateInterfere } from '../utils';

const loadingPost = () => ({ type: TYPES.loadingPostForm });

export const sendManifestation = params => {
  const tokenExpirationDate = dateInterfere(new Date(), 10, 'seconds').toISOString();

  const sigPayload = encryptAes(params.formParams);

  const signatureToken = encryptAes({
    expirationDate: tokenExpirationDate
  });

  return dispatch => {
    dispatch(loadingPost());

    createManifestation({ sigPayload, signatureToken })
      .then(({ data }) => {
        dispatch(loadingPost());

        dispatch({
          type: TYPES.formPostSuccess,
          payload: data.sigPayload,
        });
      })
      .then(() => params.callbackSuccess())
      .catch((responseFail) => {
        dispatch(loadingPost());

        dispatch({
          type: TYPES.formPostError,
          payload: responseFail.response
        });
      })
  }

}

export const changeField = (object) => ({
  type: TYPES.changeFormField,
  payload: object,
});
