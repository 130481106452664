import React from 'react';
import { Provider } from 'react-redux';
import { Grommet } from 'grommet';
import Routes from '../routes/routes';
import store from '../store/store';

import { Header } from '../../components';

import customThemeBrand from '../../config/custom-theme-brand';
import './app.css';

export default function App() {
  return (
    <Grommet theme={customThemeBrand}>
      <Provider store={store}>
        <Header />
        <Routes />
      </Provider>
    </Grommet>
  );
}
