import TYPES from '../actions/types';

const INITIAL_STATE = {
  encryptedClientDetail: null,
  clientDetailError: null,
}

const clientDetailsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.getClientDetails:
      return {
        ...state,
        encryptedClientDetail: action.payload.sigPayload,
        clientDetailError: false,
      };

    case TYPES.getClientDetailsError:
      return { ...state, clientDetailError: true };

    default:
      return state;
  }
}

export default clientDetailsReducer;
