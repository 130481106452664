import TYPES from '../actions/types';

const INITIAL_STATE = {
  individualRegistration: null,
  name: null,
  telephone: null,
  email: null,
  cityId: null,
  district: null,
  typeId: null,
  description: null,

  loadingPostForm: null,
  postListError: [],
}

const formManifestationReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.changeFormField:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value
      }

    case TYPES.loadingPostForm:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    case TYPES.formPostError:
      return { ...state, postListError: action.payload.data };

    default:
      return state;
  }
}

export default formManifestationReducer;
