import React from 'react';
import { Box, Text } from 'grommet';

const findByFieldName = (errorsList, fieldName) => {
  const actionResult = errorsList.find(a => a.fieldName === fieldName);
  return actionResult ? actionResult.message : null;
}

const ErrorForm = ({ errorsList, fieldName }) => {
  if(!errorsList || !errorsList.length) {
    return null;
  }

  const message = findByFieldName(errorsList, fieldName);

  return message ? (
    <Box margin={{ top: 'xsmall', bottom: 'xsmall' }}>
      <Text color='status-critical' size='xsmall'>
        {message}
      </Text>
    </Box>
  ) : null;
}

export default ErrorForm;
