const customThemeBrand = {
  global: {
    colors: {
      brand: {
        dark: '#3068a2',
        light: '#3068a2'
      },
      border: {
        light: 'rgba(0, 0, 0, 0.15)',
      }
    },

    drop: {
      border: { radius: '.3rem' },
      shadowSize: 'medium',
    },

    breakpoints: {
      small: {
        value: 576
      },
      medium: {
        value: 768
      },
      large: {
        value: 1200
      },
      xlarge: {
        value: 1400
      }
    },

    input: {
      weight: 400
    },

    focus: {
      border: { color: 'none' }
    }
  },

  radioButton: {
    size: '18px',
  },

  anchor: {
    fontWeight: 400
  },

  button: {
    border: {
      radius: '.3rem'
    },

    size: {
      small: {
        border: {
          radius: '.3rem'
        },
        pad: {
          horizontal: '15px'
        },
      },
      medium: {
        border: {
          radius: '.3rem'
        }
      },
      large: {
        border: {
          radius: '.3rem'
        }
      },
    },
  },
}

export default customThemeBrand;
