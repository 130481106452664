import TYPES from './types';
import { getCities } from '../api';

export const searchCityOptions = params => {
  return dispatch => {
    getCities(params.name)
      .then(({ data }) => {
        dispatch({
          type: TYPES.getCityOptions,
          payload: data.map(result => {
            const {
              id,
              name,
              stateAbbrev,
              stateId,
            } = result;

            return {
              id,
              stateId,
              name: `${name}/${stateAbbrev}`,
            }
          }),
        })
      })
  }
};
