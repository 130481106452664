const TYPES = ({
  getClientDetails: 'GET_CLIENT_DETAILS',
  getClientDetailsError: 'GET_CLIENT_DETAILS_ERROR',

  getCityOptions: 'GET_CITIES',

  // form
  changeFormField: 'CHANGE_FORM_FIELD',
  loadingPostForm: 'FORM_LOADING',
  formPostError: 'FORM_POST_ERROR',
  formPostSuccess: 'FORM_POST_SUCCESS',
});

export default TYPES;
