import { Box, ResponsiveContext, Text } from 'grommet';

const ForPopulation = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box
            border={{ side: 'top', color: 'light-3' }}
            margin={{ top: 'small', bottom: 'large' }}
          />

          <Box
            align='center'
            margin={{ bottom: 'large' }}
          >
            <Text
              color='dark-2'
              size='xlarge'
            >Para a população</Text>
          </Box>

          <Box
            direction={ size === 'small' ?  'column' : 'row' }
            gap={ size === 'medium' ? 'medium' : 'xlarge' }
          >
            <Box
              width='large'
              gap='large'
            >
              <Text
                color='dark-5'
              >
                Agora ficou bem mais fácil enviar sua manifestação para o seu representante.
              </Text>

              <Text
                color='dark-5'
              >
                Com o PARTICIPATIVA, você envia sua manifestação de onde estiver utilizando um computador, tablet ou celular.
              </Text>
            </Box>

            <Box
              width='large'
              gap='medium'
            >

              {/* passo 1 */}
              <Box
                elevation='xsmall'
                round='xsmall'
                align='stretch'
                direction='row'
                pad={{
                  horizontal: 'medium',
                  vertical: size === 'small' ? 'large' : 'medium'
                }}
                border={{ color: 'light-3' }}
                gap='medium'
              >
                <Text size='xxlarge' color='brand' weight='bold'>1</Text>

                <Text
                  size={ size === 'medium' ? 'small' : 'medium' }
                  color='dark-4'
                >
                  Acesse o link que seu representante forneceu para você.
                </Text>
              </Box>


              {/* passo 2 */}
              <Box
                elevation='xsmall'
                round='xsmall'
                align='stretch'
                direction='row'
                pad={{
                  horizontal: 'medium',
                  vertical: size === 'small' ? 'large' : 'medium'
                }}
                border={{ color: 'light-3' }}
                gap='medium'
              >
                <Text size='xxlarge' color='brand' weight='bold'>2</Text>

                <Text
                  size={ size === 'medium' ? 'small' : 'medium' }
                  color='dark-4'
                >
                  Informe seus dados e os dados da sua manufestação e clique em enviar.
                </Text>
              </Box>


              {/* passo 3 */}
              <Box
                elevation='xsmall'
                round='xsmall'
                align='stretch'
                direction='row'
                pad={{
                  horizontal: 'medium',
                  vertical: size === 'small' ? 'large' : 'medium'
                }}
                border={{ color: 'light-3' }}
                gap='medium'
              >
                <Text size='xxlarge' color='brand' weight='bold'>3</Text>

                <Text
                  size={ size === 'medium' ? 'small' : 'medium' }
                  color='dark-4'
                >
                  Ao final você receberá protocolo. Depois é só aguardar o seu representando entrar em contato.
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
}

export default ForPopulation;
