import TYPES from '../actions/types';

const INITIAL_STATE = {
  cityOptions: [],
};

const selectOptionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.getCityOptions:
      return {
        ...state,
        cityOptions: action.payload,
      };

    default:
      return state;
  }
}


export default selectOptionsReducer;
