const selectManifestationTypes = [
  { id: 1, name : 'Solicitação' },
  { id: 2, name : 'Sugestão' },
  { id: 3, name : 'Reclamação' },
  { id: 4, name : 'Elogio' },
  { id: 5, name : 'Denúncia' },
  { id: 6, name : 'Contato' },
];

export default selectManifestationTypes;
