const charactersMap = 'ABCDEFGHIJKLMNOPQRSTUVXZWabcdefghijklmnopqrstuvxzw0123456789';

const generatorId = (size) => {
  let g = '';
  let chartsLength = charactersMap.length;

  while(size--) {
    g += charactersMap.charAt(Math.floor(Math.random() * chartsLength))
  }

  return g;
}

export default generatorId;
