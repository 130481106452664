import axiosGlobalInstance from './http-instance';

const getClient = payload => {
  const { params, signatureToken } = payload;

  return axiosGlobalInstance({
    method: 'GET',
    url: '/manifestation.public.php',
    params,
    headers: {
      action: 'getManifestationClient',
      'x-signature-token': signatureToken
    }
  });
}

export default getClient;
