import crypto from 'crypto-js';
import generatorId from './gId';

const encryptAes = (value) => {
  const gId = generatorId(32);

  const hexaPass = crypto.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  const hexaIvValue = crypto.enc.Hex.parse(gId);

  const stringify = JSON.stringify(value);

  const encrypted = crypto.AES.encrypt(stringify, hexaPass, {
    iv: hexaIvValue,
    padding: crypto.pad.ZeroPadding
  });

  return `${hexaIvValue.toString()}.${encrypted.toString()}`;
}

export default encryptAes;
