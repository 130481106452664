import axiosGlobalInstance from './http-instance';

const getCities = name => {
  return axiosGlobalInstance({
    method: 'GET',
    url: '/cidade.php',
    headers: {
      authorized: 'true',
      action: 'getByName'
    },
    params: {
      name,
      timestamp: new Date().getTime()
    },
  })
}

export default getCities;
