import { Box } from 'grommet';

import Header from '../components/header';
import ForPopulation from '../components/for-population';
import ForPoliticians from '../components/for-politicians';
import Footer from '../components/footer';

const HomePage = () => {
  return (
    <Box
      align='center'
    >
      <Box
        width='xlarge'
        gap='large'
        pad={{ horizontal: 'medium' }}
      >
        <Header />
        <ForPopulation />
        <ForPoliticians />
        <Footer />
      </Box>
    </Box>
  )
}

export default HomePage;
