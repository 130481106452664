import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ResponsiveContext, Box, Text } from 'grommet';

import { decryptAes } from '../../../utils';

import urlRoutes from '../../../config/url-routes';

class ManifestationThank extends Component {
  constructor(props) {
    super(props);

    const { identifier } = this.props.match.params;
    const { history, encryptedClientDetail } = this.props;

    if(!encryptedClientDetail) {
      history.push(urlRoutes.manifestationFormUrl.replace(/:identity/gi, identifier));
    }
  }

  render() {
    const { encryptedClientDetail } = this.props;

    const decipheredData = encryptedClientDetail ? decryptAes(encryptedClientDetail) : null;
    const dataJson = JSON.parse(decipheredData);

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box align='center'>
            <Box
              width='xlarge'
              gap='large'
              pad={{ horizontal: 'medium' }}
            >
              <Box
                gap='small'
              >
                <Text
                  size='medium'
                  color='dark-1'
                >
                  Agradecemos sua manifestação!
                </Text>

                <Text
                  size='small'
                  color='dark-2'
                >
                  Sua manifestação foi recebida! Esperamos que logo ela seja atendida.
                </Text>
              </Box>

              {dataJson && dataJson.protocolNumber && (
                <Box
                  gap='small'
                >
                  <Text
                    size='medium'
                    color='dark-1'
                  >
                    Protocolo
                  </Text>

                  <Text
                    size='small'
                    color='dark-2'
                  >
                    Sua manifestão gerou o protocolo: <strong>{dataJson.protocolNumber}</strong>
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ thankManifestation }) => ({
  encryptedClientDetail: thankManifestation.encryptedPostDataSuccess
});

const mapDispatchToProps = null;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManifestationThank));
