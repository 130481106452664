import { Box, ResponsiveContext, Text } from 'grommet';

const ForPopulation = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box
            border={{ side: 'top', color: 'light-3' }}
            margin={{ top: 'small', bottom: 'large' }}
          />

          <Box
            align='center'
            margin={{ bottom: 'large' }}
          >
            <Text
              color='dark-2'
              size='xlarge'
            >Para políticos ou representantes</Text>
          </Box>

          <Box
            direction={ size === 'small' ?  'column' : 'row' }
            gap={ size === 'medium' ? 'medium' : 'xlarge' }
          >
            <Box
              width='large'
              gap='large'
            >
              <Text
                color='dark-5'
              >
                O PARTICIPATIVA, foi criado para facilitar a comunicação da população com o gabinete.
              </Text>

              <Text
                color='dark-5'
              >
                Com ele é possível enviar solicitação, sugestão, reclamação, elogio e denúncia.
              </Text>
            </Box>

            <Box
              width='large'
              gap='medium'
            >

              {/* passo 1 */}
              <Box
                elevation='xsmall'
                round='xsmall'
                align='stretch'
                direction='row'
                pad={{
                  horizontal: 'medium',
                  vertical: size === 'small' ? 'large' : 'medium'
                }}
                border={{ color: 'light-3' }}
                gap='medium'
              >
                <Text size='xxlarge' color='brand' weight='bold'>1</Text>

                <Text
                  size={ size === 'medium' ? 'small' : 'medium' }
                  color='dark-4'
                >
                  Compartilhe o seu link exclusivo com amigos, em cartazes, nas redes sociais.
                </Text>
              </Box>


              {/* passo 2 */}
              <Box
                elevation='xsmall'
                round='xsmall'
                align='stretch'
                direction='row'
                pad={{
                  horizontal: 'medium',
                  vertical: size === 'small' ? 'large' : 'medium'
                }}
                border={{ color: 'light-3' }}
                gap='medium'
              >
                <Text size='xxlarge' color='brand' weight='bold'>2</Text>

                <Text
                  size={ size === 'medium' ? 'small' : 'medium' }
                  color='dark-4'
                >
                  A população acessa seu link compartilhado e envia a manifestação.
                </Text>
              </Box>


              {/* passo 3 */}
              <Box
                elevation='xsmall'
                round='xsmall'
                align='stretch'
                direction='row'
                pad={{
                  horizontal: 'medium',
                  vertical: size === 'small' ? 'large' : 'medium'
                }}
                border={{ color: 'light-3' }}
                gap='medium'
              >
                <Text size='xxlarge' color='brand' weight='bold'>3</Text>

                <Text
                  size={ size === 'medium' ? 'small' : 'medium' }
                  color='dark-4'
                >
                  A manifestação cai direto no Gerencia Meu Mandato. A partir dai é só cuidar da manifestação.
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
}

export default ForPopulation;
