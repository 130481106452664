import { combineReducers } from 'redux';
import clientDetailsReducer from './client-details';
import thankManifestationReducer from './thank-manifestation';
import formManifestationReducer from './form-manifestation';
import selectOptionsReducer from './select-options';

const rootReducers = combineReducers({
  clientDetails: clientDetailsReducer,
  thankManifestation: thankManifestationReducer,
  formManifestation: formManifestationReducer,
  selectOptions: selectOptionsReducer,
});

export default rootReducers;
