import { Box, ResponsiveContext, Text } from 'grommet';

const Header = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          align='center'
        >
          <Box width='large' gap='medium'>
            <Text
              color='dark-2'
              size={ size === 'small' ? 'xlarge' :  'xxlarge' }
              textAlign='center'
            >
              Contribuir para o crescimento da sua cidade nunca foi tão fácil.
            </Text>

            <Text
              color='dark-2'
              size={ size === 'small' ? 'small' :  'medium' }
              textAlign='center'
            >
              Uma nova forma da população se comunicar com seus representantes.
            </Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}

export default Header;
