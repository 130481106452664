import axiosGlobalInstance from './http-instance';

const createManifestation = payload => {
  const { sigPayload, signatureToken } = payload;

  return axiosGlobalInstance({
    method: 'POST',
    url: '/manifestation.public.php',
    data: { sigPayload },
    headers: {
      action: 'create',
      'x-signature-token': signatureToken,
    }
  });
}

export default createManifestation;
