import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Analytics } from '../../components';
import { ManifestationForm, ManifestationThank } from '../../modules/manifestation/pages';
import { HomePage } from '../../modules/home/pages';

export default function Routes() {
  return (
    <Router>
      <Analytics>
        <Route exact path='/:identifier' component={ManifestationForm} />
        <Route exact path='/:identifier/thank' component={ManifestationThank} />
        <Route exact path='/' component={HomePage} />
      </Analytics>
    </Router>
  )
}
