
import TYPES from './types';
import { getClient } from '../api';
import { encryptAes, dateInterfere } from '../utils';

const getDetails = identifier => {
  const tokenExpirationDate = dateInterfere(new Date(), 10, 'seconds').toISOString();

  const params = { identifier };

  const signatureToken = encryptAes({
    expirationDate: tokenExpirationDate
  });

  return getClient({ params, signatureToken })
    .then(response => ({
      type: TYPES.getClientDetails,
      payload: response.data,
    }))
    .catch(() => ({
        type: TYPES.getClientDetailsError
    }))
}

export const loadData = identifier => {
  return [
    getDetails(identifier),
  ]
};
