import { Box, Text, Anchor, ResponsiveContext } from 'grommet';

const Footer = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box
            border={{ side: 'top', color: 'light-3' }}
            margin={{ top: 'small', bottom: 'large' }}
          />

          <Box
            direction={ size === 'small' ? 'row' : 'row' }
            gap={ size === 'small' ? 'medium' : 'xlarge' }
            margin={{ bottom: 'large' }}
            justify='between'
          >
            <Box
              width={ size === 'small' || size === 'medium' ? 'small' : 'medium' }
            >
              <Text
                color='dark-2'
                size={ size === 'small' ? 'small' : 'medium' }
              >
                Sou político ou representante e fiquei interessado.
              </Text>
            </Box>

            <Box
              width={ size === 'small' || size === 'medium' ? 'small' : 'medium' }
              gap='small'
              align='end'
            >
              <Anchor
                size={ size === 'small' ? 'small' : 'medium' }
                color='brand'
                label='(19) 9 8850.8644'
                target='blank'
                href='https://web.whatsapp.com/send?phone=5519988508644'
              />

              <Anchor
                size={ size === 'small' ? 'small' : 'medium' }
                color='brand'
                label='(19) 9 9222.2885'
                target='blank'
                href='https://web.whatsapp.com/send?phone=5519992222885'
              />

              <Anchor
                size={ size === 'small' ? 'small' : 'medium' }
                color='brand'
                label='contato@gerenciameumandato.com.br'
                href='mailto:contato@gerenciameumandato.com.br'
              />
            </Box>
          </Box>
        </>
      )}
      </ResponsiveContext.Consumer>
  );
}

export default Footer;
