import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const config = {
  trackerId: process.env.REACT_APP_ANALYTICS_TRACKER_ID,
  debug: false,
}

const Analytics = ({ children }) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState();

  useEffect(() => {
    const { trackerId, ...rest } = config;

    if(trackerId) {
      if(!initialized) {
        ReactGA.initialize(trackerId, { ...rest });
        setInitialized(true);
      }

      if(location.pathname) {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      }
    }
  }, [initialized, location]);

  return children;
}

export default Analytics;
