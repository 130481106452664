import crypto from 'crypto-js';

const decryptAes = (data) => {
  const [iv, payload] = data.split('.');

  const decrypto = crypto.AES.decrypt(
    payload,
    crypto.enc.Utf8.parse(process.env.REACT_APP_DECRYPTION_KEY),
    {
      iv: crypto.enc.Hex.parse(iv),
      padding: crypto.pad.Pkcs7
    }
  );

  return decrypto.toString(crypto.enc.Utf8);
};

export default decryptAes;
