import TYPES from '../actions/types';

const INITIAL_STATE = {
  encryptedPostDataSuccess: null,
}

const thankManifestationReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.formPostSuccess:
      return { ...state, encryptedPostDataSuccess: action.payload };

    default:
      return state;
  }
}

export default thankManifestationReducer;
